import { Helmet } from 'react-helmet-async';
import Text from '../../components/Text';

function NotFound() {
  return (
    <>
      <Helmet>
        <meta
          name='description'
          content="Désolé, la page que vous cherchez n'est pas disponible. Retournez à l'accueil d'Agence Prisma pour découvrir nos services en design et marketing."
        />
        <title>Page non trouvée | Agence Prisma</title>
      </Helmet>
      <Text
        title='404 - Page non trouvée'
        text="Désolé, la page que vous recherchez n'existe pas."
      />
    </>
  );
}

export default NotFound;
