const Text = (props) => {
  return (
    <>
      <h1 className='title'>{props.title}</h1>
      <p className='text'>{props.text}</p>
    </>
  );
};

export default Text;
