import { Helmet } from 'react-helmet-async';
import Text from '../../components/Text';

function Web() {
  return (
    <>
      <Helmet>
        <meta
          name='description'
          content='Agence Prisma : experts en création de sites Web attrayants, fonctionnels et optimisés SEO pour booster votre présence en ligne et engager votre audience.'
        />
        <title>Web | Agence Prisma</title>
      </Helmet>
      <Text
        title='Web'
        text="Un site Internet est vital pour les entreprises actuelles, agissant comme une vitrine numérique accessible mondialement 24/7. Il présente de manière efficace produits et services, améliorant la crédibilité de l'entreprise. Le site permet une interaction aisée avec les clients via des fonctionnalités interactives, et joue un rôle essentiel en marketing digital, attirant le trafic par le référencement et les réseaux sociaux. Ainsi, un site web est un outil indispensable pour renforcer la présence sur le marché et l'engagement client."
      />
    </>
  );
}

export default Web;
