import { Helmet } from 'react-helmet-async';
import Text from '../../components/Text';

function Imprimes() {
  return (
    <>
      <Helmet>
        <meta
          name='description'
          content="Agence Prisma spécialisée en solutions d'impression. Brochures, cartes de visite, et plus pour une communication impactante et professionnelle."
        />
        <title>Imprimés | Agence Prisma</title>
      </Helmet>
      <Text
        title='Imprimés'
        text="Les documents imprimés sont essentiels en communication et marketing, offrant un moyen tangible de partager l'information dans un monde numérique. Brochures, cartes de visite et rapports annuels transmettent un message professionnel et mémorable. Ils sont importants pour toucher des publics préférant les supports physiques et complètent les stratégies numériques, renforçant l'identité de la marque. Les documents imprimés demeurent ainsi un outil de communication précieux, même à l'ère numérique."
      />
    </>
  );
}

export default Imprimes;
