import { Link } from 'react-router-dom';

const Nav = () => {
  return (
    <nav className='nav'>
      <ul>
        <li>
          <Link to='/'>Accueil</Link>
        </li>
        <li>
          <Link to='/identite-visuelle'>Identité visuelle</Link>
        </li>
        <li>
          <Link to='/imprimes'>Imprimés</Link>
        </li>
        <li>
          <Link to='/web'>Web</Link>
        </li>
        {/* <li>
          <Link to='/contact'>Nous joindre</Link>
        </li> */}
      </ul>
    </nav>
  );
};

export default Nav;
