const Footer = () => {
  const now = new Date().getFullYear();
  return (
    <footer className='footer'>
      <p>Tous droits réservés {now} &copy; Agence prisma</p>
    </footer>
  );
};

export default Footer;
