// import { Routes, Route } from 'react-router-dom';
// import Home from './assets/pages/Home';
// import IdentiteVisuelle from './assets/pages/IdentiteVisuelle';
// import Imprimes from './assets/pages/Imprimes';
// import Web from './assets/pages/Web';
// import Contact from './assets/pages/Contact';

// function AppRoutes() {
//   return (
//     <Routes>
//       <Route path='/' element={<Home />} />
//       <Route
//         path='/identite-visuelle'
//         element={<IdentiteVisuelle />}
//       />
//       <Route path='/imprimes' element={<Imprimes />} />
//       <Route path='/web' element={<Web />} />
//       <Route path='/contact' element={<Contact />} />
//     </Routes>
//   );
// }

// export default AppRoutes;

// export default AppRoutes;

import React, { useRef } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import {
  SwitchTransition,
  CSSTransition,
} from 'react-transition-group';
import Home from './assets/pages/Home';
import IdentiteVisuelle from './assets/pages/IdentiteVisuelle';
import Imprimes from './assets/pages/Imprimes';
import Web from './assets/pages/Web';
import NotFound from './assets/pages/NotFound';
// ... autres imports

function AppRoutes() {
  const location = useLocation();
  const nodeRef = useRef(null); // Création d'une ref

  return (
    <SwitchTransition>
      <CSSTransition
        nodeRef={nodeRef} // Utilisation de la ref ici
        key={location.key}
        timeout={1000}
        classNames='fade'>
        <main className='main' ref={nodeRef}>
          {/* Attachement de la ref à un élément */}
          <Routes location={location}>
            <Route path='/' element={<Home />} />
            <Route
              path='/identite-visuelle'
              element={<IdentiteVisuelle />}
            />
            <Route path='/imprimes' element={<Imprimes />} />
            <Route path='/web' element={<Web />} />
            <Route path='*' element={<NotFound />} />
          </Routes>
        </main>
      </CSSTransition>
    </SwitchTransition>
  );
}

export default AppRoutes;
