import './assets/styles/App.scss';
import { BrowserRouter as Router } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import AppRoutes from './AppRoutes';
import Nav from './components/Nav';
import Footer from './components/Footer';

function App() {
  return (
    <HelmetProvider>
      <Router>
        <Nav />
        <AppRoutes />
        <Footer />
      </Router>
    </HelmetProvider>
  );
}

export default App;
