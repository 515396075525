import { Helmet } from 'react-helmet-async';
import Text from '../../components/Text';

function IdentiteVisuelle() {
  return (
    <>
      <Helmet>
        <meta
          name='description'
          content='Développez une identité visuelle unique avec Agence Prisma. Nos services incluent logos, palettes de couleurs et branding pour marquer les esprits.'
        />
        <title>Identité visuelle | Agence Prisma</title>
      </Helmet>
      <Text
        title='Identité visuelle'
        text="Une identité visuelle forte est cruciale pour toute entreprise désireuse de se distinguer. Elle reflète les valeurs et la personnalité de la marque, créant une impression durable. Combinant logo, couleurs et typographie, elle assure la reconnaissance et la confiance des consommateurs. Une identité visuelle cohérente renforce la présence de la marque sur différents supports, jouant un rôle clé dans le succès à long terme de l'entreprise."
      />
    </>
  );
}

export default IdentiteVisuelle;
