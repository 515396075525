import logo from '../img/logo-agence-prisma.svg';
import { Helmet } from 'react-helmet-async';

function Home() {
  return (
    <>
      <Helmet>
        <meta
          name='description'
          content='Bienvenue chez Agence Prisma, votre partenaire pour des solutions créatives en web design, identité visuelle, et impressions personnalisées. Découvrez plus.'
        />
        <title>Accueil | Agence Prisma</title>
      </Helmet>
      <img
        className='home-logo'
        src={logo}
        alt="Logo de l'Agence Prisma"
      />
      <h1 className='home-title'>Agence de visibilité</h1>
      <p>Ouverture bientôt</p>
    </>
  );
}

export default Home;
